<template>
  <div class="set_page">
    <div class="text_1">预订发送短信：</div>
    <a-switch default-checked :checked="checked" @change="onChange" />
    <div class="text_2">开启后，预订包间可选择是否发送短信给顾客。</div>
  </div>
</template>

<script>
import storage from 'store'
import { USER_INFO } from '@/utils/request'
import { setSmsInfo } from '@/api/personalization'

export default {
  data() {
    return {
      checked: false,
    }
  },
  created() {
    // this.getSmsInfo()
  },
  methods: {
    // 获取状态
    // getSmsInfo() {
    //   getSmsInfo({}).then((res) => {
    //     if (res.code === 0) {
    //       const result = res.data
    //       this.checked =
    //         !result?.order_room_sms || result?.order_room_sms / 1 === 1
    //     }
    //   })
    // },

    // 修改状态
    onChange(checked) {
      this.checked = checked
      const params = {
        order_room_sms: checked ? '1' : '0',
      }
      setSmsInfo(params).then((res) => {
        if (res.code === 0) {
          this.$message.success('设置成功！')

          // 同步修改本地发送带短信状态
          const userInfo = storage.get(USER_INFO)
          if (userInfo?.user?.shop_config?.sms_config) {
            userInfo.user.shop_config.sms_config.order_room_sms = String(
              res.data
            )
          }
          storage.set(USER_INFO, userInfo)
        } else {
          this.checked = !this.checked
          this.$message.error('设置失败！')
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.set_page {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 30px 0 30px 129px;
  box-sizing: border-box;
}
.text_1 {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  margin-right: 10px;
}
.text_2 {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-left: 10px;
}
</style>
